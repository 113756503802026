import Vue from "vue";
import VueResource from "vue-resource";
import Router from "vue-router";

import { BASE_URL } from "@/helpers/Constants";
import logOut from "@/mixins/logOut";

Vue.use(Router);
Vue.use(VueResource);

export default {
  mixins: [logOut],
  methods: {
    // general method for processing all requests through the application
    processRequest(url = "", method = "get", data = {}, showSpinner = true, customURL = false, contentType = "application/json") {
      if (showSpinner) {
        this.$store.commit("addRequest");
      }
      if (!customURL) {
        url = BASE_URL + url;
      }
      if (showSpinner) {
        this.$store.commit("setLoad", true);
      }
      Vue.http.interceptors.push((request, next) => {
        if (!customURL) {
          request.headers.set("Authorization", localStorage.token);
        }
        next();
      });
      return Vue.http[method](url, data, {
        headers: { "Content-Type": contentType, 'Access-Control-Allow-Origin': location.origin }
      }).then(
        response => {
          if (showSpinner) this.$store.commit("removeRequest");
          if (this.$store.state.activeRequests === 0) {
            this.$store.commit("setLoad", false);
          }
          return response;
        },
        response => {
          if (showSpinner) this.$store.commit("removeRequest");
          if (this.$store.state.activeRequests === 0) {
            this.$store.commit("setLoad", false);
          }
          if (response?.body?.message === "Not Authenticated") {
            this.logOut();
            // todo need to use toast message globally
            //  or find the way how to block other notifications when we receive "Not Authenticated" response
            this.$awn.warning("Your session has been expired. Please login again");
          }
          return response;
        }
      );
    },
    requestApi(url = "", method = "get", data = {}, showSpinner = true, showError = true, contentType = "application/json") {
      if (showSpinner) {
        this.$store.commit("addRequest");
        this.$store.commit("setLoad", true);
      }
      return Vue.http[method](url, data, {
        headers: { "Content-Type": contentType, 'Access-Control-Allow-Origin': location.origin, Authorization: localStorage.token }
      }).then(
        response => {
          if (showSpinner) this.$store.commit("removeRequest");
          if (this.$store.state.activeRequests === 0) {
            this.$store.commit("setLoad", false);
          }
          return response;
        },
        response => {
          if (showSpinner) this.$store.commit("removeRequest");
          if (this.$store.state.activeRequests === 0) {
            this.$store.commit("setLoad", false);
          }
          if (response?.status === 401) {
            this.logOut();
          }
          if (showError) {
            this.$awn.warning(`Can not ${method} ${url.split('/').unshift()}`);
          }
          return Promise.reject(response);
        }
      );
    }
  }
};
