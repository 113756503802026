/* eslint-disable */
import Vue from 'vue';
import { directive as onClickOutside } from 'vue-on-click-outside';
import Vuelidate from 'vuelidate';
import Datetime from 'vue-datetime';
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueLogger from 'vuejs-logger';

import store from '@/store/store';
import router from '@/router';
import App from '@/app/App';
import IsJsonString from '@/mixins/CheckJsonString';
import "@/libs/css/ckeditor.css";
import MiddlewareService from "@/MiddlewareService.js";
import { UrlLogger } from '@/helpers/Constants';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: !isProduction,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: !isProduction,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options);

Vue.use(Vuelidate);
Vue.use(Datetime);
Vue.directive('on-click-outside', onClickOutside);

Vue.config.productionTip = true;

Vue.use(CKEditor);

new Vue({
  el: '#app',
  mixins: [MiddlewareService, IsJsonString],
  data() {
    return {
      clientData: {}
    };
  },
  router,
  store,
  methods: {
    getMessage(msg, vm, info) {
      return "<div>" +
      `<div><strong>Error on ${location.href}</strong></div>` +
      `<div><span style='color: red'>Message: ${msg}</span></div>` +
      `<div><span  style='color: red'>Info: ${info}</span></div>` +
      "<div><strong>Component: </strong></div>" +
      `<div><span>${vm.$vnode ? vm.$vnode.tag : vm}</span></div>` +
      `<div><span>CompanyID: </span><strong>${localStorage?.companyId ?? this.$route.params.companyId}</strong></div>` +
      `${this.getRows()}` +
      "</div>";
    },
    getRows() {
      let strHtml = "";
      for (let key in this.clientData) {
        strHtml += `<div><span>${key}: </span><strong>${this.clientData[key]}</strong></div>`;
      }
      return strHtml;
    },
    async getClientData(url) {
      let data = await this.processRequest(url, "get", {}, true, true);
      if (data?.ok && data?.body) {
        this.clientData = data.body;
        return true;
      }
      return false;
    },
    async sendReport(msg, src, error) {
      // get only ip address
      // await this.getClientData('https://api.ipify.org/?format=json');
      try {
        await this.requestApi(`${UrlLogger}/log`, 'post', {
          error: this.getMessage(msg, src, error),
          note: `client: ${process.env.MODE}`
        }, false, false);
      } catch (ex) {
        console.log(ex);
      }
      console.log('error: ', msg);
    }
  },
  created() {
    if ((process.env.MODE === 'prod' || process.env.MODE === 'stage') && location.host.indexOf('localhost') === -1) {
      window.onerror = async(msg, src, linenum, colnum, error) => {
        this.sendReport(msg, src, error);
      };
      Vue.config.errorHandler = async(err, vm, info) => {
        this.sendReport(err, vm, info);
      };
      Vue.config.warnHandler = async(msg, vm, info) => {
        this.sendReport(msg, vm, info);
      };
    }
  },
  render: h => h(App),
});
