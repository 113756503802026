import lang from "@/helpers/lang";

export default {
  data() {
    return {
      lang
    };
  },
  methods: {
    getType() {
      const store = this.$store.state;
      let type = 'no';
      if (store.selectedLang !== undefined && Object.keys(store.selectedLang).length === 0) {
        type = localStorage.lang;
      } else {
        type = store.selectedLang?.name;
      }
      if (this.$awn) {
        this.$awn.options.labels = {
          success: this.lang[type].toast.success,
          warning: this.lang[type].toast.warning,
          alert: this.lang[type].toast.alert,
        };
      }
      return type;
    }
  }
};
