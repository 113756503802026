export default {
  data() {
    return {
      norwegian: 'no',
      swedish: 'sv',
      english: 'en',
    };
  },
  methods: {
    /**
     * Set current language if it was found in $store.state.languages
     * by provided acronym (like 'en', 'no', 'sv').
     * If provided language was not found in $store.state.languages or it isn't provided:
     * Set Norwegian -> If not found -> set Swedish -> If not found -> set English
     * @param {string} lang
     * @return {undefined}
     */
    setCompanyLanguage(lang) {
      const languages = this.$store.state.languages;
      this.$store.commit('setLang',
        (languages.find(el => el.name === lang) ??
          (languages.find(el => el.name === this.norwegian) || languages.find(el => el.name === this.swedish) || languages.find(el => el.name === this.english))));
    },
    /**
     * Set English, Norwegian and Swedish
     * as default languages into state.
     * Used in Admin side (CMS)
     * @return {undefined}
     */
    setDefaultLanguagesCms() {
      this.$store.commit('setLangs', [
        { name: 'no' },
        { name: 'sv' },
        { name: 'en' },
      ]);
    },
    /**
     * Set English and Norwegian as default languages into state.
     * Used in Client side
     * @return {undefined}
     */
    setDefaultLanguagesClient() {
      this.$store.commit('setLangs', [
        { name: 'no' },
        { name: 'en' },
      ]);
    },
  }
};
