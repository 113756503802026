import { clientComponents } from "@/helpers/client/componentsRegister";

export const clientRoutes = {
  path: '/',
  component: clientComponents.mainClient,
  meta: {
    admin: false,
    login: false
  },
  children: [
    {
      path: "/:buildingId/:companyId/:siteId/queueNumber/:queueName/:queueId",
      name: "queueNumber",
      component: clientComponents.QueueNumber,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/queueInitials/:queueName/:queueId",
      name: "queueInitials",
      component: clientComponents.QueueInitials,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/feedback/:template/:email",
      name: "feedback-v",
      component: clientComponents.FeedBackForm,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/left/:visitorId",
      name: "left-visitor",
      component: clientComponents.LeftVisitor,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/expressVisit/:employeeId",
      name: "registration",
      component: () => import("@/components/client/visitExpress/registration")
    },
    {
      path: "/expressVisitArrival/:visitorId",
      name: "visitor-express-confirm",
      component: () => import("@/components/client/visitExpress/confirmation")
    },
    {
      path: "/:buildingId/:companyId/:siteId/meetingroom/:meetingName",
      name: "meeting-v",
      component: clientComponents.HomeMeetings,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/meetingroom/:meetingName/:filterByDate",
      name: "meeting-filter-date",
      component: clientComponents.HomeMeetings,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/infoSearch/:infoSearchName",
      name: "home-info",
      component: clientComponents.HomeInfoSearch,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/infoSearch/:infoSearchName/categoryDetail",
      name: "category-info",
      component: clientComponents.CategoryInfoSearch,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/infoSearch/:infoSearchName/areaDetail",
      name: "area-info",
      component: clientComponents.AreaInfoSearch,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/visitors/:visitorsName",
      name: "home-v",
      component: clientComponents.HomeVisitors,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/visitors/:visitorsName/register",
      name: "register-v",
      component: clientComponents.VisitorsRegister,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/visitors/:visitorsName/search",
      name: "search-v",
      component: clientComponents.VisitorsSearch,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/visitors/:visitorsName/sms",
      name: "sms-v",
      component: clientComponents.VisitorsSms,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/visitors/:visitorsName/finish",
      name: "finish-v",
      component: clientComponents.VisitorsFinish,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path:
        "/:buildingId/:companyId/:siteId/visitors/:visitorsName/department-sms",
      name: "departmentSms-v",
      component: clientComponents.DepartmentSms,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path:
        "/:buildingId/:companyId/:siteId/visitors/:visitorsName/dropin-message",
      name: "dropin-message-v",
      component: clientComponents.DropinMessage,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path:
        "/:buildingId/:companyId/:siteId/visitors/:visitorsName/dropin-registartion",
      name: "dropin-registartion-v",
      component: clientComponents.DropinRegistartion,
      meta: {
        admin: false,
        login: false
      }
    },
    {
      path: "/:buildingId/:companyId/:siteId/visitors/:visitorsName/queue-register",
      name: "queueRegistration-v",
      component: clientComponents.QueueVisitorsRegistration,
      meta: {
        admin: false,
        login: false
      }
    },
  ]
};
