const dynamicComponent = () => import("@/components/dynamicClient/client/dynamic");

export default {
  /**
   * Adding new routes into current routes dynamic
   * @param {Array} routes
   * */
  methods: {
    addRoutesDynamic(routes = []) {
      if (routes?.length === 0) return;

      this.$router.addRoutes(routes
        .map((r, index) =>
          // Added one basic client component that will be used for all routes
          ({
            path: r.path,
            name: r.id,
            component: dynamicComponent,
            props: {
              settings: {
                ...r,
              }
            }
          })));

      const home = routes?.find(r => r.isHomePage)?.id ?? routes[0].id;
      // redirect to the loaded routes home page
      this.$router.push({
        name: home,
        params: {
          siteId: this.getParameterFromQuery(),
        },
        query: { dynamic: this.$router.history.current.query.dynamic ?? 'true' }
      });
    },
    /**
     * Parse url and return site id
     * @param {Number} index. Position of parameter in url, site Id by default
     * @return {String} siteId
     * */
    getParameterFromQuery(index = 1) {
      const parsedPath = this.$route?.path?.split('/');
      // check if path has more than 2 segments
      // Todo: think how to solve dynamic route preparation for infosearch
      if (parsedPath.length > 2) {
        index = 2;
      }
      // as URL should be correct -> siteId will be on this place
      return parsedPath[index];
    }
  }
};
