import constants from "@/store/client/constants/client";

export default {
  setInfoSearchCategories(state, categories) {
    state.infoSearchCategories = categories;
  },
  setInfoSearchCategory(state, category) {
    state.infoSearchCategory = category;
  },
  setPageObject(state, pageObject) {
    state.selectedPage = pageObject;
  },
  isWorkDay(state, isWorkDay) {
    state.isWorkDay = isWorkDay;
  },
  setPatiens(state, patients) {
    state.patients = patients;
  },
  setAreas(state, areas) {
    state.areas = areas;
  },
  setArea(state, area) {
    state.area = area;
  },
  setDefaultEmployers(state, employees) {
    state.defaultEmployees = employees;
  },
  setVisitor(state, visitor) {
    state.visitor = visitor;
  },
  setEmployee(state, employee) {
    state.employee = employee;
  },
  addToInfoCatStatistic(state, collection) {
    state.infoCatStatisticCollection = [...state.infoCatStatisticCollection.map(item => ({ ...item })), ...[collection]];
  },
  cleanInfoCatStatistic(state) {
    state.infoCatStatisticCollection = [];
  },
  [constants.setCompanyName](state, name) {
    state.companyName = name;
  }
};
