import constants from '@/store/admin/constants/admin';
import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to get evacuation messages
     *  @param {String} languageId. Current languageId
     *  @param {companyId} companyId. Current companyId
     */
    async getEvacuationMessagesForSite(languageId, companyId) {
      const evacuationMessages = await this.processRequest(`/EvacuationMessages/List/${companyId || localStorage.companyId}/${languageId}`);
      if (evacuationMessages?.body?.success) {
        this.$store.commit(constants.setSiteEvacuationMessages, evacuationMessages.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(evacuationMessages));
      }
    }
  }
};
