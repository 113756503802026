// All CMS components names

export default {
  mainCms: "mainCms",
  CmsContent: "CmsContent",
  NavigationPanel: "NavigationPanel",
  Login: "Login",
  Sites: "Sites",
  SiteEdit: "SiteEdit",
  SitePages: "SitePages",
  SitesNamedContent: "SitesNamedContent",
  Templates: "Templates",
  EditTemplate: "EditTemplate",
  TemplatePages: "TemplatePages",
  ActiveVisitors: "ActiveVisitors",
  Buildings: "Buildings",
  BuildingsEdit: "BuildingsEdit",
  AdminBuildings: "AdminBuildings",
  AdminBuildingsEdit: "AdminBuildingsEdit",
  Companies: "Companies",
  CompaniesEdit: "CompaniesEdit",
  AdminCompanies: "AdminCompanies",
  AdminCompaniesEdit: "AdminCompaniesEdit",
  CompanySettingsEdit: "CompanySettingsEdit",
  Departments: "Departments",
  DepartmentsEdit: "DepartmentsEdit",
  AdminDepartments: "AdminDepartments",
  AdminDepartmentsEdit: "AdminDepartmentsEdit",
  JobDepartments: "JobDepartments",
  JobDepartmentsEdit: "JobDepartmentsEdit",
  JobTitles: "JobTitles",
  JobTitlesEdit: "JobTitlesEdit",
  Users: "Users",
  UsersEdit: "UsersEdit",
  userActivity: "userActivity",
  CompanyUsers: "CompanyUsers",
  CompanyUsersEdit: "CompanyUsersEdit",
  DropinUsers: "DropinUsers",
  DropinUsersEdit: "DropinUsersEdit",
  Messages: "Messages",
  MessagesEdit: "MessagesEdit",
  CompanyMessages: "CompanyMessages",
  CompanyMessagesEdit: "CompanyMessagesEdit",
  DropinMessages: "DropinMessages",
  DropinMessagesEdit: "DropinMessagesEdit",
  VisitorMessages: "VisitorMessages",
  VisitorMessagesEdit: "VisitorMessagesEdit",
  EvacuationMessages: "EvacuationMessages",
  EvacuationMessagesEdit: "EvacuationMessagesEdit",
  PreregistrationMessages: "PreregistrationMessages",
  UserGroup: "UserGroup",
  UserGroupEdit: "UserGroupEdit",
  Statistics: "Statistics",
  PreRegistration: "PreRegistration",
  MeetingRoom: "MeetingRoom",
  Meetings: "Meetings",
  MessageSettings: "MessageSettings",
  SiteSettings: "SiteSettings",
  LoginPictureSettings: "LoginPictureSettings",
  MeetingRoomEdit: "MeetingRoomEdit",
  Editor: "Editor",
  TemplateEditor: "TemplateEditor",
  InfoSearchBase: "InfoSearchBase",
  InfoSearchSites: "InfoSearchSites",
  InfoSearchSitesEdit: "InfoSearchSitesEdit",
  InfoSearchAreas: "InfoSearchAreas",
  InfoSearchAreasEdit: "InfoSearchAreasEdit",
  InfoSearchPatients: "InfoSearchPatients",
  InfoSearchPatientsEdit: "InfoSearchPatientsEdit",
  InfoSearchCategory: "InfoSearchCategory",
  InfoSearchCategoryEdit: "InfoSearchCategoryEdit",
  InfoSearchStatistics: "InfoSearchStatistics",
  QueueSystemLines: "QueueSystemLines",
  QueueSystemLinesEdit: "QueueSystemLinesEdit",
  QueueSystemLinesManage: "QueueSystemLinesManage",
  Versions: "Versions",
  EnturLines: "EnturLines",
  EnturLineEdit: "EnturLineEdit",
  PreRegisterMeetings: "PreRegisterMeetings",
  PreRegisterMeetingEdit: "PreRegisterMeetingEdit",
  PreRegisterMeetingStatus: "PreRegisterMeetingStatus",
};
