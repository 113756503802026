import { cmsComponents } from "@/helpers/cms/componentsRegister";
import navigationKeys from "@/helpers/cms/navigationKeys";

export const adminRoutes = {
  path: "/admin",
  component: cmsComponents.mainCms,
  meta: {
    breadcrumbs: [
      {
        name: navigationKeys.admin,
        routeName: 'admin'
      }
    ],
    admin: true,
    login: true
  },
  children: [
    {
      path: ":module/named-content/:serviceId",
      name: "sites-named-content",
      component: cmsComponents.SitesNamedContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services",
          },
          {
            name: navigationKeys.sitesNamedContent,
            routeName: "sites-named-content",
          }
        ],
        parent: "services",
        admin: true,
        login: true
      }
    },
    {
      path: "sites",
      name: "services",
      component: cmsComponents.Sites,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services"
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: ":module/site-edit/:siteId",
      name: "site-edit",
      component: cmsComponents.InfoSearchSitesEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: 'services'
          },
          {
            name: navigationKeys.infoSearchSites,
            routeName: "site-edit",
            editable: true,
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "admin",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.admin,
            routeName: "admin"
          }
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "admin",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "buildings",
          name: "buildings",
          component: cmsComponents.Buildings,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.buildings,
                routeName: "buildings"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "buildings/:buildingId",
          name: "building-edit",
          component: cmsComponents.BuildingsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.buildings,
                routeName: "buildings"
              },
              {
                name: navigationKeys.buildings,
                routeName: "buildings",
                editable: true
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "companies",
          name: "companies",
          component: cmsComponents.Companies,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "companies/:companyId",
          name: "companies-edit",
          component: cmsComponents.CompaniesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies",
                editable: true
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "companies-settings-edit/:companyId",
          name: "companies-settings-edit",
          component: cmsComponents.CompanySettingsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies"
              },
              {
                name: navigationKeys.companies,
                routeName: "companies-settings-edit",
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "departments",
          name: "departments",
          component: cmsComponents.Departments,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "departments"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "departments/:departmentId",
          name: "departments-edit",
          component: cmsComponents.DepartmentsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "departments"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "users",
          name: "users",
          component: cmsComponents.Users,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.users,
                routeName: "users"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "users/:userId",
          name: "users-edit",
          component: cmsComponents.UsersEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.users,
                routeName: "users"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "user-activity",
          name: "userActivity",
          component: cmsComponents.userActivity,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.userActivities,
                routeName: "userActivity"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "messages",
          name: "messages",
          component: cmsComponents.Messages,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.notifications,
                routeName: "messages"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "messages/:messageId",
          name: "messages-edit",
          component: cmsComponents.MessagesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.notifications,
                routeName: "messages"
              },
              {
                name: navigationKeys.notifications,
                routeName: "messages",
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "user-group",
          name: "user-groups",
          component: cmsComponents.UserGroup,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.userRoles,
                routeName: "user-groups"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "user-group-edit/:roleId",
          name: "user-group-edit",
          component: cmsComponents.UserGroupEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.admin,
                routeName: "admin"
              },
              {
                name: navigationKeys.userRoles,
                routeName: "user-groups"
              },
              {
                name: navigationKeys.userRoles,
                routeName: "user-groups",
                editable: true
              }
            ],
            admin: true,
            login: true
          },
        },
      ]
    },
    {
      path: "meetingroomedit/:serviceId",
      name: "meetingroomedit",
      component: cmsComponents.MeetingRoomEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services"
          },
          {
            name: navigationKeys.meetingRooms,
            routeName: 'meetingroomedit'
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "meetingEmail/:serviceId",
      name: "meetingEmail",
      component: cmsComponents.MeetingEmail,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services"
          },
          {
            name: navigationKeys.meetingRooms,
            routeName: 'meetingroomedit'
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "meetings/:serviceId",
      name: "meetings",
      component: cmsComponents.Meetings,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services"
          },
          {
            name: navigationKeys.meetings,
            routeName: 'meetings',
            editable: true
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "login",
      name: "login",
      component: cmsComponents.Login,
      meta: {
        admin: true,
        login: false
      }
    },
    {
      path: "visitor/:serviceId",
      name: "active-visitors",
      component: cmsComponents.ActiveVisitors,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services"
          },
          {
            name: navigationKeys.userActivities,
            routeName: "visitor",
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "service/:serviceId",
      name: "services-edit",
      component: cmsComponents.SiteEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sites,
            routeName: "services"
          },
          {
            name: navigationKeys.sites,
            routeName: "services",
            editable: true
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "templates",
      name: "templates",
      component: cmsComponents.Templates,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: "templates"
          }
        ],
        admin: true,
        login: true
      },
    },
    {
      path: "templates/:templateId",
      name: "edit-template",
      component: cmsComponents.EditTemplate,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: "templates"
          },
          {
            name: navigationKeys.templates,
            routeName: "templates",
            editable: true
          }
        ],
        admin: true,
        login: true
      },
      children: [
      ]
    },
    {
      path: "pages",
      name: "template-pages",
      component: cmsComponents.TemplatePages,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: 'template-pages'
          }
        ],
        admin: true,
        login: true
      },
    },
    {
      path: "pages/:pageId/editor",
      name: "template-page-editor",
      component: cmsComponents.TemplateEditor,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.templates,
            routeName: 'template-pages'
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "site/:module?/:siteId/pages",
      name: "site-pages",
      component: cmsComponents.SitePages,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sitePages,
            routeName: 'site-pages'
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: ":module?/site/:siteId/pages/:pageId/editor",
      name: "page-editor",
      component: cmsComponents.Editor,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.sitePages,
            routeName: 'page-editor'
          }
        ],
        admin: true,
        login: true
      }
    },

    // company admin routes
    {
      path: "company-admin",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.companyAdmin,
            routeName: 'company-admin'
          }
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "company-admin",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "admin-buildings",
          name: "admin-buildings",
          component: cmsComponents.AdminBuildings,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.buildings,
                routeName: "admin-buildings"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "admin-buildings/:buildingId",
          name: "admin-buildings-edit",
          component: cmsComponents.AdminBuildingsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.buildings,
                routeName: "admin-buildings"
              },
              {
                name: navigationKeys.buildings,
                routeName: "admin-buildings-edit",
                editable: true
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "admin-departments",
          name: "admin-departments",
          component: cmsComponents.AdminDepartments,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.adminDepartments,
                routeName: "admin-departments"
              },
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "admin-departments/:departmentId",
          name: "admin-departments-edit",
          component: cmsComponents.AdminDepartmentsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.adminDepartments,
                routeName: "admin-departments"
              },
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "job-departments",
          name: "job-departments",
          component: cmsComponents.JobDepartments,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobDepartments,
                routeName: "job-departments"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "job-departments/:categoryId",
          name: "job-departments-edit",
          component: cmsComponents.JobDepartmentsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobDepartments,
                routeName: "job-departments"
              },
              {
                name: navigationKeys.jobDepartments,
                routeName: "job-departments-edit",
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "job-titles",
          name: "job-titles",
          component: cmsComponents.JobTitles,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobTitles,
                routeName: "job-titles"
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "job-titles/:jobTitleId",
          name: "job-titles-edit",
          component: cmsComponents.JobTitlesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.jobTitles,
                routeName: "job-titles-edit"
              },
              {
                name: navigationKeys.jobTitles,
                routeName: "job-titles-edit",
              }
            ],
            admin: true,
            login: true
          },
        },
        {
          path: "admin-companies",
          name: "admin-companies",
          component: cmsComponents.AdminCompanies,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "admin-companies/:companyId",
          name: "admin-companies-edit",
          component: cmsComponents.AdminCompaniesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies-edit",
                editable: true
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "admin-companies-settings-edit/:companyId",
          name: "admin-companies-settings-edit",
          component: cmsComponents.CompanySettingsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies"
              },
              {
                name: navigationKeys.companies,
                routeName: "admin-companies-settings-edit",
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "employees",
          name: "employees",
          component: cmsComponents.CompanyUsers,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.employees,
                routeName: "employees"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "employees/:userId",
          name: "employees-edit",
          component: cmsComponents.CompanyUsersEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.employees,
                routeName: "employees"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "dropin",
          name: "dropin",
          component: cmsComponents.DropinUsers,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "dropin"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "dropin/:userId",
          name: "dropin-edit",
          component: cmsComponents.DropinUsersEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "dropin"
              },
              {
                name: navigationKeys.dropIns,
                routeName: "dropin-edit",
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "admin-messages",
          component: cmsComponents.CmsContent,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.companyAdmin,
                routeName: "company-admin"
              },
              {
                name: navigationKeys.companyAdminMessages,
                routeName: "admin-messages"
              }
            ],
            admin: true,
            login: true
          },
          children: [
            {
              path: "",
              name: "admin-messages",
              component: cmsComponents.NavigationPanel,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "employees-messages",
              name: "employees-messages",
              component: cmsComponents.CompanyMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.employeeNotifications,
                    routeName: "employees-messages",
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "employees-messages/:messageId",
              name: "employees-messages-edit",
              component: cmsComponents.CompanyMessagesEdit,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.employeeNotifications,
                    routeName: "employees-messages",
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "dropin-messages",
              name: "dropin-messages",
              component: cmsComponents.DropinMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.dropInNotifications,
                    routeName: "dropin-messages",
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "dropin-messages/:messageId",
              name: "dropin-messages-edit",
              component: cmsComponents.DropinMessagesEdit,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.dropInNotifications,
                    routeName: "dropin-messages-edit",
                  }
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "visitor-messages",
              name: "visitor-messages",
              component: cmsComponents.VisitorMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.visitorNotifications,
                    routeName: "visitor-messages",
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "visitor-messages/:messageId",
              name: "visitor-messages-edit",
              component: cmsComponents.VisitorMessagesEdit,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.visitorNotifications,
                    routeName: "visitor-messages-edit",
                  }
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "evacuation-messages",
              name: "evacuation-messages",
              component: cmsComponents.EvacuationMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.evacuationMessages,
                    routeName: "evacuation-messages",
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "evacuation-messages/:messageId",
              name: "evacuation-messages-edit",
              component: cmsComponents.EvacuationMessagesEdit,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.evacuationMessages,
                    routeName: "evacuation-messages-edit",
                  },
                ],
                admin: true,
                login: true
              }
            },
            {
              path: "preregistration-messages",
              name: "preregistration-messages",
              component: cmsComponents.PreregistrationMessages,
              meta: {
                breadcrumbs: [
                  {
                    name: navigationKeys.companyAdmin,
                    routeName: "company-admin"
                  },
                  {
                    name: navigationKeys.companyAdminMessages,
                    routeName: "admin-messages"
                  },
                  {
                    name: navigationKeys.preregistrationMessages,
                    routeName: "preregistration-messages",
                  },
                ],
                admin: true,
                login: true
              }
            },
          ]
        },
      ]
    },
    {
      path: "statistics",
      name: "statistics",
      component: cmsComponents.Statistics,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.statistics,
            routeName: "statistics"
          }
        ],
        admin: true,
        login: true
      }
    },
    {
      path: "settings",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.settings,
            routeName: "settings"
          }
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "settings",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "settings-message",
          name: "settings-message",
          component: cmsComponents.MessageSettings,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.settingsMessages,
                routeName: "settings-message"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "settings-sites",
          name: "settings-sites",
          component: cmsComponents.SiteSettings,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.siteSettings,
                routeName: "settings-sites"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "versions",
          name: "versions",
          component: cmsComponents.Versions,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.versions,
                routeName: "versions"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "login-picture-settings",
          name: "login-picture-settings",
          component: cmsComponents.LoginPictureSettings,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.settings,
                routeName: "settings"
              },
              {
                name: navigationKeys.loginPictureSettings,
                routeName: "login-picture-settings"
              }
            ],
            admin: true,
            login: true
          }
        },
      ]
    },
    {
      path: "infosearch",
      component: cmsComponents.CmsContent,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.infoSearch,
            routeName: "infosearch"
          },
        ],
        admin: true,
        login: true
      },
      children: [
        {
          path: "",
          name: "infosearch",
          component: cmsComponents.NavigationPanel,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "sites",
          name: "infosearch-sites",
          component: cmsComponents.InfoSearchSites,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchSites,
                routeName: "infosearch-sites"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "sites-edit/:siteId",
          name: "sites-edit",
          component: cmsComponents.InfoSearchSitesEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchSites,
                routeName: "infosearch-sites"
              },
              {
                name: navigationKeys.infoSearchSites,
                routeName: "sites-edit",
                editable: true
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-patients",
          name: "infosearch-patients",
          component: cmsComponents.InfoSearchPatients,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchPatients,
                routeName: "infosearch-patients"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-patients-edit/:patientId",
          name: "infosearch-patients-edit",
          component: cmsComponents.InfoSearchPatientsEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchPatients,
                routeName: "infosearch-patients"
              }
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-areas",
          name: "infosearch-areas",
          component: cmsComponents.InfoSearchAreas,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchAreas,
                routeName: "infosearch-areas"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-areas-edit/:areaId",
          name: "infosearch-areas-edit",
          component: cmsComponents.InfoSearchAreasEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchAreas,
                routeName: "infosearch-areas"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-categories",
          name: "infosearch-categories",
          component: cmsComponents.InfoSearchCategory,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchCategories,
                routeName: "infosearch-categories"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-category-edit/:categoryId",
          name: "infosearch-category-edit",
          component: cmsComponents.InfoSearchCategoryEdit,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchCategories,
                routeName: "infosearch-categories"
              },
              {
                name: "",
                routeName: "infosearch-category-edit"
              },
            ],
            admin: true,
            login: true
          }
        },
        {
          path: "infosearch-statistics",
          name: "infosearch-statistics",
          component: cmsComponents.InfoSearchStatistics,
          meta: {
            breadcrumbs: [
              {
                name: navigationKeys.infoSearch,
                routeName: "infosearch"
              },
              {
                name: navigationKeys.infoSearchStatistics,
                routeName: "infosearch-statistics"
              },
            ],
            admin: true,
            login: true
          }
        },
      ]
    },
    {
      path: "queue-system-lines",
      name: "queue-system-lines",
      component: cmsComponents.QueueSystemLines,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.queue,
            routeName: "queue-system-lines"
          },
        ],
        parent: "queue-system",
        admin: true,
        login: true
      }
    },
    {
      path: "queue-system-lines-edit/:queueId",
      name: "queue-system-lines-edit",
      component: cmsComponents.QueueSystemLinesEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.queue,
            routeName: "queue-system-lines"
          }
        ],
        parent: "queue-system",
        admin: true,
        login: true
      }
    },
    {
      path: "queue-system-lines-manage/:queueId",
      name: "queue-system-lines-manage",
      component: cmsComponents.QueueSystemLinesManage,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.queue,
            routeName: "queue-system-lines"
          }
        ],
        parent: "queue-system",
        admin: true,
        login: true
      }
    },
    {
      path: "entur-lines",
      name: "entur-lines",
      component: cmsComponents.EnturLines,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.enturLines,
            routeName: "entur-lines"
          },
        ],
        parent: "entur",
        admin: true,
        login: true
      }
    },
    {
      path: "entur-line-edit/:id",
      name: "entur-line-edit",
      component: cmsComponents.EnturLineEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.enturLineEdit,
            routeName: "entur-line-edit"
          }
        ],
        parent: "entur-lines",
        admin: true,
        login: true
      }
    },
    {
      path: "pre-register-meetings",
      name: "pre-register-meetings",
      component: cmsComponents.PreRegisterMeetings,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meetings"
          }
        ],
        parent: "pre-register-meeting",
        admin: true,
        login: true
      }
    },
    {
      path: "pre-register-meeting/:id",
      name: "pre-register-meeting",
      component: cmsComponents.PreRegisterMeetingEdit,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meetings"
          },
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meeting",
            editable: true
          }
        ],
        parent: "pre-register-meetings",
        admin: true,
        login: true
      }
    },
    {
      path: "pre-register-status/:id",
      name: "pre-register-status",
      component: cmsComponents.PreRegisterMeetingStatus,
      meta: {
        breadcrumbs: [
          {
            name: navigationKeys.preRegisterMeetings,
            routeName: "pre-register-meetings"
          }
        ],
        parent: "pre-register-meetings",
        admin: true,
        login: true
      }
    },
  ]
};
