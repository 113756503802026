import Vue from "vue";
import VueResource from "vue-resource";
import Router from "vue-router";
import routes from "@/helpers/router/routes";

Vue.use(Router);
Vue.use(VueResource);

const router = new Router({
  routes: routes
});
Vue.http.interceptors.push(function(request, next) {
  next(function(response) {
    if (response.status === 401 || response.status === 403) {
      router.app.$store.commit("setLangs", [{ name: "en" }, { name: "no" }]);
      localStorage.removeItem("token");
      localStorage.removeItem("companyId");
      localStorage.removeItem("buildingId");
      localStorage.removeItem("companyName");
      router.push("/admin/login");
    }
  });
});

export default router;
