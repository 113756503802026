import constants from "@/store/admin/constants/admin";

export default {
  setUserActivity(state, users) {
    state.usersActivity = users;
  },
  showVersion(state, open) {
    state.showVersion = open;
    state.versionIsColapsed = true;
  },
  showDetailedVersion(state, open) {
    state.showVersion = open;
    state.versionIsColapsed = false;
  },
  setDropinIndex(state, index) {
    state.DropinIndex = index;
  },
  blockContent(state, blockContent) {
    state.blockContent = blockContent;
  },
  addRequest(state) {
    state.activeRequests = state.activeRequests + 1;
  },
  removeRequest(state) {
    if (state.activeRequests > 0) {
      state.activeRequests = state.activeRequests - 1;
    }
  },
  setLoad(state, loading) {
    state.loading = loading;
  },
  [constants.setSite](state, site) {
    state.site = site;
  },
  [constants.setSites](state, sites) {
    state.sites = sites;
  },
  setLang(state, lang) {
    state.selectedLang = lang;
  },
  setLangs(state, languages) {
    state.languages = languages;
  },
  setBuildings(state, buildings) {
    state.buildings = buildings;
  },
  setCompanies(state, companies) {
    state.companies = companies;
  },
  setBuilding(state, building) {
    state.building = building;
  },
  setCompany(state, company) {
    state.company = company;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setUser(state, user) {
    state.user = user;
  },
  setDepartments(state, departments) {
    state.departments = departments;
  },
  setDepartment(state, department) {
    state.department = department;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setCategory(state, category) {
    state.category = category;
  },
  setJobTitles(state, jobTitles) {
    state.jobTitles = jobTitles;
  },
  setJobTitle(state, jobTitle) {
    state.jobTitle = jobTitle;
  },
  setJobDepartments(state, jobDepartments) {
    state.jobDepartments = jobDepartments;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setRole(state, role) {
    state.role = role;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  setClaims(state, claims) {
    state.claims = claims;
  },
  setEmployees(state, employees) {
    state.employees = employees;
  },
  setAllEmployees(state, employees) {
    state.allEmployees = employees;
  },
  showUndoDialog(state, open) {
    state.showUndoDialog = open;
  },
  showMobileMenu(state, open) {
    state.showMobileMenu = open;
  },
  setHistory(state, history) {
    state.history = history;
  },
  setLoginPicture(state, role) {
    state.loginPicture = role;
  },
  [constants.openCloseCsvUploadEmployeesPopup](state, showDialog) {
    state.showCsvUploadDialog = showDialog;
  },
  [constants.openCloseCsvDownloadEmployeesPopup](state, showDialog) {
    state.showCsvDownloadDialog = showDialog;
  },
  [constants.openCloseCsvUploadErrorsPopup](state, showDialog) {
    state.showCsvUploadErrorsPopup = showDialog;
  },
  [constants.setCsvUploadErrors](state, errors) {
    state.csvUploadEmployeesError = errors;
  },
  [constants.setInfoSearchPatients](state, patients) {
    state.infoSearchPatients = patients;
  },
  [constants.setInfoSearchPatientsSorted](state, patients) {
    state.infoSearchPatientsSorted = patients.map(patient => {
      const p = { ...patient };
      if (patient.Area) {
        p.Area = {
          ...patient.Area,
          AreaContent: patient.Area.AreaContent.map(areaContent => ({ ...areaContent }))
        };
      }
      return p;
    });
  },
  [constants.setInfoSearchPatientsSortOptions](state, sortOptions) {
    state.infoSearchPatientsSortOptions = { ...sortOptions };
  },
  [constants.setPages](state, payload) {
    state.pages = payload;
  },
  [constants.selectPage](state, payload) {
    state.pages = [...state.pages].map(p => {
      p = {
        ...p,
        active: false
      };
      if (p.id === payload.id) {
        p.active = true;
      }
      return p;
    });
  },
  [constants.selectTemplate](state, payload) {
    state.templates = [...state.templates].map(t => {
      t = {
        ...t,
        active: false
      };
      if (t.id === payload.id) {
        t.active = true;
      }
      return t;
    });
  },
  [constants.setTemplates](state, payload) {
    state.templates = payload;
  },
  [constants.setTemplate](state, payload) {
    state.template = payload;
  },
  [constants.toggleUploadImagePopup](state, showDialog) {
    state.showImageUploadPopup = showDialog;
  },
  [constants.setEditorFiles](state, payload) {
    state.editorFiles = payload;
  },
  [constants.selectEditorFile](state, payload) {
    state.editorFiles = [...state.editorFiles].map(f => {
      f = {
        ...f,
        active: false
      };
      if (f.id === payload.id) {
        f.active = true;
      }
      return f;
    });
  },
  [constants.openCloseEvacuationSmsDialog](state, payload) {
    state.showSelectEvacuationMessageDialog = payload;
  },
  [constants.openCloseEvacuationSmsConfirmationDialog](state, payload) {
    state.showEvacuationSmsConfirmationDialog = payload;
  },
  [constants.openCloseEvacuationBuildingDialog](state, payload) {
    state.showBuildingEvacuationDialog = payload;
  },
  [constants.openCloseEvacuationBuildingConfirmationDialog](state, payload) {
    state.showBuildingEvacuationConfirmationDialog = payload;
  },
  [constants.openCloseSuccessPopup](state, payload) {
    state.showSuccessPopup = payload;
  },
  [constants.setSiteEvacuationMessages](state, payload) {
    state.siteEvacuationMessages = payload.map(m => ({
      id: m.id,
      name: m.messageText,
    }));
  },
  [constants.setActiveVisitorsIds](state, payload) {
    state.activeVisitorsIdsToBeEvacuated = payload.map(v => v.id);
  },
  [constants.setEvacuationMessageText](state, payload) {
    state.selectedEvacuationMessageText = payload;
  },
  [constants.setBuildingVisitors](state, payload) {
    state.buildingVisitors = payload;
  },
  [constants.setAccessRoutes](state, payload) {
    state.accessRoutes = payload;
  },
  [constants.externalEmployees](state, payload) {
    state.externalEmployees = payload;
  },
  [constants.companySelectIonDialog](state, showDialog) {
    state.showCompanySelectionDialog = showDialog;
  },
  [constants.setNavigationObject](state, payload) {
    state.navigationObject = payload;
  },
};
