import { button } from "@/helpers/wc/buttonSettings";
import { input } from "@/helpers/wc/inputSettings";
import { image } from "@/helpers/wc/imageSettings";
import { text } from "@/helpers/wc/textSettings";
import { employeeListSettings } from "@/helpers/wc/employeeListSettings";
import { queueVisitorsList } from "@/helpers/wc/queueVisitorsListSettings";
import { queueOperatedVisitor } from "@/helpers/wc/queueOperatedVisitorSettings";
import { langSwitch } from "@/helpers/wc/langSwitchSettings";
import { keyBoard } from "@/helpers/wc/keyBoardSettings";
import { panel } from "@/helpers/wc/panelSettings";
import { iframe } from "@/helpers/wc/iFrameSettings";
import { checkBox } from "@/helpers/wc/checkBoxSettings";
import { departmentsSettings } from "@/helpers/wc/departmentsSettings";
import { departmentUsersSettings } from "@/helpers/wc/departmentUsersSettings";
import { infoSearchCategoryContentSettings } from "@/helpers/wc/infoSearchCategoryContentSettings";
import { infoSearchSubCategoriesButtonsSettings } from "@/helpers/wc/infoSearchSubCategoriesButtonsSettings";
import { stopPointTimingSettings } from "@/helpers/wc/stopPointTimingSettings";
import { clockSettings } from "@/helpers/wc/clockSettings";
import { responseMessageSettings } from "@/helpers/wc/responseMessageSettings";
import { deceasedListSettings } from "@/helpers/wc/deceasedListSettings";
import { graveyardSettings } from "@/helpers/wc/graveyardSettings";
import { infoSearchPatientsSettings } from "@/helpers/wc/infoSearchPatientsSettings";

// default components register
export default [
  button,
  input,
  image,
  text,
  employeeListSettings,
  queueVisitorsList,
  queueOperatedVisitor,
  langSwitch,
  keyBoard,
  panel,
  iframe,
  checkBox,
  departmentsSettings,
  departmentUsersSettings,
  infoSearchCategoryContentSettings,
  infoSearchSubCategoriesButtonsSettings,
  stopPointTimingSettings,
  clockSettings,
  responseMessageSettings,
  deceasedListSettings,
  graveyardSettings,
  infoSearchPatientsSettings
];
