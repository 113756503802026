// local IIS api
// export const BASE_URL = 'https://localhost:44389/api';

// stage api by default
//eslint-disable-line

import claimsRegister from '@/helpers/claims';

let url = "https://api.stage.procon.cloud/api";
let urlEntur = "https://jexyqg3voh.execute-api.eu-central-1.amazonaws.com/Prod";
let urlMeetings = "https://jt21cmg0aa.execute-api.eu-central-1.amazonaws.com/Prod";
let urlConfigurator = "https://8933kcy1s6.execute-api.eu-central-1.amazonaws.com/Prod";
let urlChurch = "https://kx3u8wale6.execute-api.eu-central-1.amazonaws.com/Prod";
let urlLogger = "https://oyaugrlg6g.execute-api.eu-central-1.amazonaws.com/Prod";
let urlPreRegisterMeeting = "https://rkntsnqyj6.execute-api.eu-central-1.amazonaws.com/Prod";
let urlVisitors = "https://y8c0c61ia1.execute-api.eu-central-1.amazonaws.com/Prod";

// ui stage url
let host = 'https://stage.procon.cloud';
let socket = 'wss://';

// avantor stage :buildingId
let avantorBuilding = 'R03ap2LT';
// eslint-disable-next-line no-unused-vars
let avantorCompany = '4zbewNUO';
// eslint-disable-next-line no-unused-vars
let avantorSite = 'fXBFoavH';

// :siteId
let AvantorSiteID = 'vauAx4yV';
let BrickSiteID = 'fdfY1Ty4';
let CitrixSiteID = '4JEmK4kW';
let ArvalSiteID = 'jWOOjnrB';
let NyarkSiteID = 's8xhzmcT';
let ReguskSiteID = 'ooUXO4ES';
let SatseliSiteID = 'HHOswh1P';
let TheACSiteID = '3GaWUy5J';
let NovoSiteID = 'MtzfGWE3';
let MadarkSiteID = 'WOH2wqER';
let FortiusSiteID = 'GU8xlxjp';
let IteraSiteID = 's5RA5bUa';
let StatnetSiteID = '2JEfW7oA';
let ProffcomSiteID = 'S1aaxLxQ';
let TelehusetSiteID = 'oIR5tSOq';
let SquareheadSiteID = '6RrpPrfX';
let StanleySiteID = 'kkWKfe7V';

// :companyId
let Telehuset = "4zbewNUO";
let Squarehead = "4zbewNUO";
let Itera = "4zbewNUO";
let ArvalS = "4zbewNUO";
let StanleySecurity = "4zbewNUO";
let Statnett = "4zbewNUO";
let SatsElixia = "4zbewNUO";
let Citrix = "4zbewNUO";
let Avantor = "4zbewNUO";
let NydalenArkitektkontor = "4zbewNUO";
let BrickAS = "4zbewNUO";
let NovoNordiskScandinaviaAS = "4zbewNUO";
let MADAS = "4zbewNUO";
let TheAssessmentCompanyAS = "4zbewNUO";
let Proffcom = "4zbewNUO";
let Regus = "4zbewNUO";
let FortiusProsjekt = "4zbewNUO";

// ISS Group Sites stage
// :buildingId
let issBuilding = "xT3nOV4H";

// :siteId
let NAVSiteID = "wh1Mol9e";
let StatensSiteID = "paDAysEe";
let LanekassenSiteID = "szpF1wXp";

// :companyId
let NAV = "ITfTopG0";
let Statens = "YzN9lIsL";
let Lanekassen = "z596dWYv";

// prod api
if (process.env.MODE === 'prod') {
  url = 'https://api.prod.procon.cloud/api';

  socket = 'wss://';

  // ui prod url
  host = 'https://procon.cloud';

  // avantor prod :buildingId
  avantorBuilding = 'sSVVd3Kv';
  avantorCompany = 'I2jegn9Y';
  avantorSite = 'ccLsXVtp'; // need change

  // :companyId
  Telehuset = "2Ok8WkCx";
  Squarehead = "7YHI4kFF";
  Itera = "A0nlKsYz";
  ArvalS = "aWamN28d";
  StanleySecurity = "axB4bGS0";
  Statnett = "bvKslP2o";
  SatsElixia = "D5CHt8lS";
  Citrix = "Flls5Nk8";
  Avantor = "I2jegn9Y";
  NydalenArkitektkontor = "LcxN4pSV";
  BrickAS = "NrfEYGKv";
  NovoNordiskScandinaviaAS = "rWI6cQ3x";
  MADAS = "TSjWXgFc";
  TheAssessmentCompanyAS = "TwbE3ntk";
  Proffcom = "w3e8tBUo";
  Regus = "vTHnEylR";
  FortiusProsjekt = "Y97Tu15m";

  // :siteId
  AvantorSiteID = 'uLNKQUV2';
  BrickSiteID = 'q7wzMJUm';
  CitrixSiteID = 'XI0geoq6';
  ArvalSiteID = 'Tu2n13LB';
  NyarkSiteID = '9uMTDo4b';
  ReguskSiteID = 'drRQEmUA';
  SatseliSiteID = '9RsC9V26';
  TheACSiteID = 'zBAsAJvU';
  NovoSiteID = 'lPJx1WEL';
  MadarkSiteID = 'fs2VRxr9';
  FortiusSiteID = 'Q16CzcT9';
  IteraSiteID = 'sErRUSXr';
  StatnetSiteID = '4P4gHKxL';
  ProffcomSiteID = 'C7cymjIC';
  TelehusetSiteID = 'mGCOFbvD';
  SquareheadSiteID = '4ekPFp6X';
  StanleySiteID = 'HDy8k6qz';

  // ISS Group Sites prod
  // :buildingId
  issBuilding = "xT3nOV4H";

  // :siteId
  NAVSiteID = "wh1Mol9e";
  StatensSiteID = "paDAysEe";
  LanekassenSiteID = "szpF1wXp";

  // :companyId
  NAV = "ITfTopG0";
  Statens = "YzN9lIsL";
  Lanekassen = "z596dWYv";
}

// local docker compose api
if (process.env.MODE === 'dev') {
  url = 'https://localhost:44389/api';
  urlEntur = "https://localhost:44367";
  urlMeetings = "https://localhost:63298";
  urlConfigurator = "https://localhost:44374";
  urlChurch = "https://localhost:44317";
  urlLogger = "https://localhost:44300";
  urlPreRegisterMeeting = "https://localhost:44333";
  urlVisitors = "https://localhost:44386";
  // ui prod url
  host = 'https://localhost:8000';

  socket = 'ws://';

  // avantor prod
  avantorBuilding = 'sSVVd3Kv';
  avantorCompany = 'I2jegn9Y';
  avantorSite = 'ccLsXVtp'; // need change

  Telehuset = "2Ok8WkCx";
  Squarehead = "7YHI4kFF";
  Itera = "A0nlKsYz";
  ArvalS = "aWamN28d";
  StanleySecurity = "axB4bGS0";
  Statnett = "bvKslP2o";
  SatsElixia = "D5CHt8lS";
  Citrix = "Flls5Nk8";
  Avantor = "I2jegn9Y";
  NydalenArkitektkontor = "LcxN4pSV";
  BrickAS = "NrfEYGKv";
  NovoNordiskScandinaviaAS = "rWI6cQ3x";
  MADAS = "TSjWXgFc";
  TheAssessmentCompanyAS = "TwbE3ntk";
  Proffcom = "w3e8tBUo";
  Regus = "vTHnEylR";
  FortiusProsjekt = "Y97Tu15m";

  AvantorSiteID = 'uLNKQUV2';
  BrickSiteID = 'q7wzMJUm';
  CitrixSiteID = 'XI0geoq6';
  ArvalSiteID = 'Tu2n13LB';
  NyarkSiteID = '9uMTDo4b';
  ReguskSiteID = 'drRQEmUA';
  SatseliSiteID = '9RsC9V26';
  TheACSiteID = 'zBAsAJvU';
  NovoSiteID = 'lPJx1WEL';
  MadarkSiteID = 'fs2VRxr9';
  FortiusSiteID = 'Q16CzcT9';
  IteraSiteID = 'sErRUSXr';
  StatnetSiteID = '4P4gHKxL';
  ProffcomSiteID = 'C7cymjIC';
  TelehusetSiteID = 'mGCOFbvD';
  SquareheadSiteID = '4ekPFp6X';
  StanleySiteID = 'HDy8k6qz';
}

export const BASE_URL = url;
export const UrlEntur = urlEntur;
export const UrlMeetings = urlMeetings;
export const UrlConfigurator = urlConfigurator;
export const UrlChurch = urlChurch;
export const UrlLogger = urlLogger;
export const UrlPreRegisterMeeting = urlPreRegisterMeeting;
export const UrlVisitors = urlVisitors;

// ui url
export const UIHost = host;
export const SOCKET = socket;

// Groped sites that have one building with multiple companies

// avantor prod
export const AvantorGroupHome = `${UIHost}/#/R03ap2LT/4zbewNUO/fXBFoavH/visitors/AvantorGroup`;

export const AvantorSite = `${UIHost}/#/${avantorBuilding}/${Avantor}/${AvantorSiteID}/visitors/AvantorSite`;
export const BrickSite = `${UIHost}/#/${avantorBuilding}/${BrickAS}/${BrickSiteID}/visitors/BrickSite`;
export const CitrixSite = `${UIHost}/#/${avantorBuilding}/${Citrix}/${CitrixSiteID}/visitors/CitrixSite`;
export const ArvalSite = `${UIHost}/#/${avantorBuilding}/${ArvalS}/${ArvalSiteID}/visitors/ArvalSite`;
export const NyarkSite = `${UIHost}/#/${avantorBuilding}/${NydalenArkitektkontor}/${NyarkSiteID}/visitors/NyarkSite`;
export const ReguskSite = `${UIHost}/#/${avantorBuilding}/${Regus}/${ReguskSiteID}/visitors/ReguskSite`;
export const SatseliSite = `${UIHost}/#/${avantorBuilding}/${SatsElixia}/${SatseliSiteID}/visitors/SatseliSite`;
export const TheACSite = `${UIHost}/#/${avantorBuilding}/${TheAssessmentCompanyAS}/${TheACSiteID}/visitors/TheACSite`;
export const NovoSite = `${UIHost}/#/${avantorBuilding}/${NovoNordiskScandinaviaAS}/${NovoSiteID}/visitors/NovoSite`;
export const MadarkSite = `${UIHost}/#/${avantorBuilding}/${MADAS}/${MadarkSiteID}/visitors/MadarkSite`;
export const FortiusSite = `${UIHost}/#/${avantorBuilding}/${FortiusProsjekt}/${FortiusSiteID}/visitors/FortiusSite`;
export const IteraSite = `${UIHost}/#/${avantorBuilding}/${Itera}/${IteraSiteID}/visitors/IteraSite`;
export const StatnetSite = `${UIHost}/#/${avantorBuilding}/${Statnett}/${StatnetSiteID}/visitors/StatnetSite`;
export const ProffcomSite = `${UIHost}/#/${avantorBuilding}/${Proffcom}/${ProffcomSiteID}/visitors/ProffcomSite`;
export const TelehusetSite = `${UIHost}/#/${avantorBuilding}/${Telehuset}/${TelehusetSiteID}/visitors/TelehusetSite`;
export const SquareheadSite = `${UIHost}/#/${avantorBuilding}/${Squarehead}/${SquareheadSiteID}/visitors/SquareheadSite`;
export const StanleySite = `${UIHost}/#/${avantorBuilding}/${StanleySecurity}/${StanleySiteID}/visitors/StanleySite`;

// ISS Group Sites (production)
export const ISSHome = `${UIHost}/#/xT3nOV4H/z596dWYv/pFAbl0A8/visitors/ISS`;

export const NAVSite = `${UIHost}/#/${issBuilding}/${NAV}/${NAVSiteID}/visitors/NAV`;
export const StatensSite = `${UIHost}/#/${issBuilding}/${Statens}/${StatensSiteID}/visitors/Statens`;
export const LanekassenSite = `${UIHost}/#/${issBuilding}/${Lanekassen}/${LanekassenSiteID}/visitors/Lanekassen`;

export const DATE_FORMAT_HH_MM = "YYYY-MM-DDTHH:mm:ss.SSS";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_HH = "HH:mm:ss.SSS";
export const HOUR_FORMAT_POPUP = "HH:mm";
export const DATE_FORMAT_SIMPLE_HH_MM = "YYYY-MM-DD HH:mm";
export const DATE_FORMAT_SIMPLE_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT_ONLY_HH_MM = "HH:mm";
export const DATE_FORMAT_CALENDAR_DATE = "DD. MMMM YYYY";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_POPUP = "YYYY/MM/DD";
export const DATE_FORMAT_PRINT_LABEL = "DD.MM.YYYY";
export const DATE_FORMAT_PRINT_DDDD_DD_MMMM_YYY = "dddd DD. MMMM YYYY";
export const INTERVAL_RENDER_MEETING_ROOM = 120000;
export const VERSION_NUMBER = '2.01';
export const socketTypes = {
  ping: 'ping',
  all: 'all',
  reload: 'reload',
  reloadSuccess: 'Reload Success',
};
export const notificationTypes = {
  employee: 0,
  dropin: 1,
  visitors: 2,
  evacuation: 3,
};
export const shiftTypes = {
  lower: 0,
  upper: 1,
  caps: 2
};

export const keyBoardFuncTypes = {
  button: 'button',
  shift: 'shift',
  number: 'number',
  space: 'space',
  enter: 'enter',
  del: "del"
};

export const logicalOperators = {
  division: 'division',
  multiplication: 'multiplication'
};

export const _SheetJSFT = [
  "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(x => "." + x).join(",");

export const AceeptExcelCsv = ["csv", "xlsx"].map(x => "." + x).join(",");

export const EMAIL_TEMPLATE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
export const INCREASE_IFRAME_RULES = /<a([^>]*[^/])>iframe|&amp;w\d+|&amp;wauto|&amp;h\d+|&amp;hauto<\/a>/g; //eslint-disable-line
export const FEEDBACK_FORM_RULE = /^.*feedback-(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g; //eslint-disable-line

export const claims = claimsRegister;

export const countryCodes = [
  {
    code: '+47',
    country: 'no-en'
  },
  // ! hide in prod
  // * can be uncommitted to tests
  // {
  //   code: '+380',
  //   country: 'ua'
  // },
  {
    code: '+46',
    country: 'sv'
  }
];
