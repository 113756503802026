// Register all components for CMS

import components from "@/helpers/cms/components";

/*
 General
 */
const mainCms = () => import("@/components/cms/main/main");
const CmsContent = () => import("@/components/cms/main/CmsContent");
const NavigationPanel = () => import("@/components/cms/layout/navigation/panels/NavigationPanel");
const Login = () => import("@/components/cms/login/login");
const Sites = () => import("@/components/cms/sites/sites");
const SiteEdit = () => import("@/components/cms/sites/editSite/editSite");
const SitePages = () => import("@/components/cms/sites/editSite/sitePages/sitePages");
const Templates = () => import("@/components/cms/templates/templates");
const EditTemplate = () => import("@/components/cms/templates/editTemplate/editTemplate");
const TemplatePages = () => import("@/components/cms/templates/editTemplate/templatePages/templatePages");
const SitesNamedContent = () => import("@/components/cms/sites/namedContent/namedContent");

/*
 Admin
 */
const Buildings = () => import("@/components/cms/admin/buildings/buildings");
const BuildingsEdit = () => import("@/components/cms/admin/buildings/buildings-edit");
const Companies = () => import("@/components/cms/admin/companies/companies");
const CompaniesEdit = () => import("@/components/cms/admin/companies/companies-edit");
const Departments = () => import("@/components/cms/admin/departments/departments");
const DepartmentsEdit = () => import("@/components/cms/admin/departments/departments-edit");
const Users = () => import("@/components/cms/admin/users/users");
const UsersEdit = () => import("@/components/cms/admin/users/users-edit");
const userActivity = () => import("@/components/cms/admin/userActivity/userActivity");
const Messages = () => import("@/components/cms/admin/messages/messages");
const MessagesEdit = () => import("@/components/cms/admin/messages/messages-edit");
const UserGroup = () => import("@/components/cms/admin/user-group/user-group");
const UserGroupEdit = () => import("@/components/cms/admin/user-group/user-group-edit");

/*
  Company Admin
 */
const ActiveVisitors = () => import("@/components/cms/companyAdmin/visitors-list/active-visitors");
const AdminBuildings = () => import("@/components/cms/companyAdmin/buildings/buildings");
const AdminBuildingsEdit = () => import("@/components/cms/companyAdmin/buildings/buildings-edit");
const AdminCompanies = () => import("@/components/cms/companyAdmin/companies/companies");
const AdminCompaniesEdit = () => import("@/components/cms/companyAdmin/companies/companies-edit");
const AdminDepartments = () => import("@/components/cms/companyAdmin/departments/departments");
const AdminDepartmentsEdit = () => import("@/components/cms/companyAdmin/departments/departments-edit");
const JobDepartments = () => import("@/components/cms/companyAdmin/jobDepartments/job-departments");
const JobDepartmentsEdit = () => import("@/components/cms/companyAdmin/jobDepartments/job-departments-edit");
const JobTitles = () => import("@/components/cms/companyAdmin/jobTitles/jobTitles");
const JobTitlesEdit = () => import("@/components/cms/companyAdmin/jobTitles/jobTitles-edit");
const CompanyUsers = () => import("@/components/cms/companyAdmin/Employees/users");
const CompanyUsersEdit = () => import("@/components/cms/companyAdmin/Employees/users-edit");
const DropinUsers = () => import("@/components/cms/companyAdmin/Dropin/users");
const DropinUsersEdit = () => import("@/components/cms/companyAdmin/Dropin/users-edit");
const CompanyMessages = () => import("@/components/cms/companyAdmin/messages/employersMessages/messages");
const CompanyMessagesEdit = () => import("@/components/cms/companyAdmin/messages/employersMessages/messages-edit");
const DropinMessages = () => import("@/components/cms/companyAdmin/messages/dropinMessages/messages");
const DropinMessagesEdit = () => import("@/components/cms/companyAdmin/messages/dropinMessages/messages-edit");
const VisitorMessages = () => import("@/components/cms/companyAdmin/messages/visitorMessages/messages");
const VisitorMessagesEdit = () => import("@/components/cms/companyAdmin/messages/visitorMessages/messages-edit");
const EvacuationMessages = () => import("@/components/cms/companyAdmin/messages/evacuationMessages/evacuation-messages");
const EvacuationMessagesEdit = () => import("@/components/cms/companyAdmin/messages/evacuationMessages/evacuation-messages-edit");
const PreregistrationMessages = () => import("@/components/cms/companyAdmin/messages/preregistrationMessages/preregistration-messages");

/*
  Common
 */
const Statistics = () => import("@/components/cms/common/statistics/statistics");
const MessageSettings = () => import("@/components/cms/common/settings/messageSettings");
const SiteSettings = () => import("@/components/cms/common/settings/Sites/sitesSettings");
const Versions = () => import("@/components/cms/common/settings/versions");
const LoginPictureSettings = () => import("@/components/cms/common/settings/loginPictureSettings");

/*
  Web Constructor
 */
const Editor = () => import("@/components/cms/webConstructor/sitePageEditor/editor");
const TemplateEditor = () => import("@/components/cms/webConstructor/templatePageEditor/editor");

/*
  InfoSearch
*/
const InfoSearchBase = () => import("@/components/cms/infosearch/infoSearchBase");
const InfoSearchSites = () => import("@/components/cms/infosearch/sites/sites");
const InfoSearchSitesEdit = () => import("@/components/cms/infosearch/sites/sites-edit");
const InfoSearchAreas = () => import("@/components/cms/infosearch/areas/areas");
const InfoSearchAreasEdit = () => import("@/components/cms/infosearch/areas/areas-edit");
const InfoSearchPatients = () => import("@/components/cms/infosearch/patients/patients");
const InfoSearchPatientsEdit = () => import("@/components/cms/infosearch/patients/patients-edit");
const InfoSearchCategory = () => import("@/components/cms/infosearch/category/category");
const InfoSearchCategoryEdit = () => import("@/components/cms/infosearch/category/category-edit");
const InfoSearchStatistics = () => import("@/components/cms/infosearch/statistics");

/*
  Modules
 */
const PreRegistration = () => import("@/components/cms/modules/preregistration/preregistration");
const MeetingRoomEdit = () => import("@/components/cms/modules/meetingRoom/meetingRoom-editCustomName");
const Meetings = () => import("@/components/cms/modules/meetingRoom/meetings");
const MeetingEmail = () => import("@/components/cms/modules/meetingRoom/meetingEmail");
const QueueSystemLines = () => import("@/components/cms/modules/queueSystem/lines/lines");
const QueueSystemLinesEdit = () => import("@/components/cms/modules/queueSystem/lines/lines-edit");
const QueueSystemLinesManage = () => import("@/components/cms/modules/queueSystem/lines/lines-manage");
const EnturLines = () => import("@/components/cms/modules/entur/lines");
const EnturLineEdit = () => import("@/components/cms/modules/entur/line-edit");
const CompanySettingsEdit = () => import("@/components/cms/modules/companySettings/companySettings");
const PreRegisterMeetings = () => import("@/components/cms/preRegisterMeeting/preRegisterMeetings");
const PreRegisterMeetingEdit = () => import("@/components/cms/preRegisterMeeting/preRegisterMeetingEdit");
const PreRegisterMeetingStatus = () => import("@/components/cms/preRegisterMeeting/preRegisterMeetingStatus");

export const cmsComponents = {
  [components.mainCms]: mainCms,
  [components.CmsContent]: CmsContent,
  [components.NavigationPanel]: NavigationPanel,
  [components.Login]: Login,
  [components.Sites]: Sites,
  [components.SiteEdit]: SiteEdit,
  [components.SitePages]: SitePages,
  [components.SitesNamedContent]: SitesNamedContent,
  [components.Templates]: Templates,
  [components.EditTemplate]: EditTemplate,
  [components.TemplatePages]: TemplatePages,
  [components.ActiveVisitors]: ActiveVisitors,
  [components.Buildings]: Buildings,
  [components.BuildingsEdit]: BuildingsEdit,
  [components.AdminBuildings]: AdminBuildings,
  [components.AdminBuildingsEdit]: AdminBuildingsEdit,
  [components.Companies]: Companies,
  [components.CompaniesEdit]: CompaniesEdit,
  [components.AdminCompanies]: AdminCompanies,
  [components.AdminCompaniesEdit]: AdminCompaniesEdit,
  [components.CompanySettingsEdit]: CompanySettingsEdit,
  [components.Departments]: Departments,
  [components.DepartmentsEdit]: DepartmentsEdit,
  [components.AdminDepartments]: AdminDepartments,
  [components.AdminDepartmentsEdit]: AdminDepartmentsEdit,
  [components.JobDepartments]: JobDepartments,
  [components.JobDepartmentsEdit]: JobDepartmentsEdit,
  [components.JobTitles]: JobTitles,
  [components.JobTitlesEdit]: JobTitlesEdit,
  [components.Users]: Users,
  [components.UsersEdit]: UsersEdit,
  [components.userActivity]: userActivity,
  [components.CompanyUsers]: CompanyUsers,
  [components.CompanyUsersEdit]: CompanyUsersEdit,
  [components.DropinUsers]: DropinUsers,
  [components.DropinUsersEdit]: DropinUsersEdit,
  [components.Messages]: Messages,
  [components.MessagesEdit]: MessagesEdit,
  [components.CompanyMessages]: CompanyMessages,
  [components.CompanyMessagesEdit]: CompanyMessagesEdit,
  [components.DropinMessages]: DropinMessages,
  [components.DropinMessagesEdit]: DropinMessagesEdit,
  [components.VisitorMessages]: VisitorMessages,
  [components.VisitorMessagesEdit]: VisitorMessagesEdit,
  [components.EvacuationMessages]: EvacuationMessages,
  [components.EvacuationMessagesEdit]: EvacuationMessagesEdit,
  [components.PreregistrationMessages]: PreregistrationMessages,
  [components.UserGroup]: UserGroup,
  [components.UserGroupEdit]: UserGroupEdit,
  [components.Statistics]: Statistics,
  [components.PreRegistration]: PreRegistration,
  [components.Meetings]: Meetings,
  [components.MeetingEmail]: MeetingEmail,
  [components.MessageSettings]: MessageSettings,
  [components.SiteSettings]: SiteSettings,
  [components.Versions]: Versions,
  [components.LoginPictureSettings]: LoginPictureSettings,
  [components.MeetingRoomEdit]: MeetingRoomEdit,
  [components.Editor]: Editor,
  [components.TemplateEditor]: TemplateEditor,
  [components.InfoSearchBase]: InfoSearchBase,
  [components.InfoSearchSites]: InfoSearchSites,
  [components.InfoSearchSitesEdit]: InfoSearchSitesEdit,
  [components.InfoSearchAreas]: InfoSearchAreas,
  [components.InfoSearchAreasEdit]: InfoSearchAreasEdit,
  [components.InfoSearchPatients]: InfoSearchPatients,
  [components.InfoSearchPatientsEdit]: InfoSearchPatientsEdit,
  [components.InfoSearchCategory]: InfoSearchCategory,
  [components.InfoSearchCategoryEdit]: InfoSearchCategoryEdit,
  [components.InfoSearchStatistics]: InfoSearchStatistics,
  [components.QueueSystemLines]: QueueSystemLines,
  [components.QueueSystemLinesEdit]: QueueSystemLinesEdit,
  [components.QueueSystemLinesManage]: QueueSystemLinesManage,
  [components.EnturLines]: EnturLines,
  [components.EnturLineEdit]: EnturLineEdit,
  [components.PreRegisterMeetings]: PreRegisterMeetings,
  [components.PreRegisterMeetingEdit]: PreRegisterMeetingEdit,
  [components.PreRegisterMeetingStatus]: PreRegisterMeetingStatus,
};
