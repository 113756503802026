export default {
  setSiteResolution: 'setSiteResolution',
  setPages: 'setPages',
  setPage: 'setPage',
  setCoefficient: 'setCoefficient',
  setActivePage: 'setActivePage',
  updateActivePageArray: 'updateActivePageArray',
  deactivatePageComponents: 'deactivatePageComponents',
  updatePageComponents: 'updatePageComponents',
  activateComponent: 'activateComponent',
  setEmployeeSearchString: 'setEmployeeSearchString',
  setEmployees: 'setEmployeeList',
  setAllEmployees: 'setAllEmployees',
  setEmployee: 'setEmployeeObj',
  setDefaultEmployees: 'setDefaultEmployeeList',
  setVisitor: 'setVisitorObj',
  setLocalPageSize: 'setLocalPageSize',
  saveDropinSettings: 'saveDropinSettings',
  validateInput: 'validateInput',
  setTemplateActivePage: 'setTemplateActivePage',
  setCompanyQueueLines: 'setCompanyQueueLines',
  setQueueVisitors: 'setQueueVisitors',
  setJobDepartments: 'setJobDepartments',
  setDepartmentUsers: 'setDepartmentUsers',
  setJobDepartmentUsers: 'setJobDepartmentUsers',
  setJobDepartment: 'setJobDepartment',
  jobDepartments: 'jobDepartments',
  infoSearchCategories: 'infoSearchCategories',
  infoSearchCategory: 'infoSearchCategory',
  infoSearchSubCategory: 'infoSearchSubCategory',
  setInfoSearchCategory: 'setInfoSearchCategory',
  setInfoSearchSubCategory: 'setInfoSearchSubCategory',
  setInfoSearchCategories: 'setInfoSearchCategories',
  setFinishRegisterSettings: 'setFinishRegisterSettings',
  setElementsShifted: 'setElementsShifted',
  parkingRegister: 'parkingRegister',
  taxiOrder: 'taxiOrder',
  graveyard: 'graveyard',
  patient: 'patient',
  searchPage: 'searchPage',
  buttonState: 'buttonState'
};
