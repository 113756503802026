import { componentKeys } from "@/helpers/siteSetings";
import { button } from "@/helpers/wc/buttonSettings";
import _ from "lodash";

export default {
  getByKeys: state => (stateKey, propertyKey) => {
    if (Object.keys(state.selectedLang).length) {
      const stateObj = state[stateKey];
      return stateObj.map(el => {
        el[propertyKey] = el[propertyKey]?.map(item => {
          item = {
            ...item,
            show: false
          };
          if (item.languageId === state?.selectedLang?.id) {
            item = {
              ...item,
              show: true
            };
          }
          return item;
        });
        const active = el[propertyKey]?.find(c => c.show);
        el = {
          ...el,
          name: active ? active?.name : ''
        };
        return el;
      });
    } else {
      return [];
    }
  },
  getActiveComponent: state => {
    return state.selectedPage?.[componentKeys.components]?.find(c => c.active);
  },
  getComponentByName: state => nameKey => (state.selectedPage?.[componentKeys.components]?.find(c => c.name === nameKey)),
  getComponentsFromPage: (state) => {
    if (Object.keys(state.selectedPage).length) {
      return _.cloneDeep(state.selectedPage
        ?.[componentKeys.components]?.map((el, index) => ({
          ...el,
          active: el.active ?? false,
        }))) ?? [];
    }
    return [];
  },
  getButtonsFromPage: (state) => {
    if (Object.keys(state.selectedPage).length) {
      return _.cloneDeep(state.selectedPage
        ?.[componentKeys.components]?.map((el, index) => ({
          ...el,
          active: el.active ?? false,
        })).filter(c => c[componentKeys.name] === button[componentKeys.name])) ?? [];
    }
    return [];
  },
  getDepartmentsList: (state, getters) => {
    return getters.getByKeys("departments", "content");
  },
  getRoutes: (state, getters) => {
    return getters.getPages.filter(el => el.id !== state.selectedPage?.id);
  },
  getCompanyQueueLines: (state, getters) => {
    return getters.getByKeys("companyQueueLines", "queueContent");
  },
  getJobDepartments: (state, getters) => {
    return getters.getByKeys("jobDepartments", "content");
  },
  getInfoSearchCategories: (state) => {
    return state.infoSearchCategories;
  },
  getDepartmentUsers: (state) => {
    if (Object.keys(state.selectedLang).length) {
      return state.departmentUsers;
    } else {
      return [];
    }
  },
  getQueueVisitors: (state) => {
    if (Object.keys(state.queueVisitors).length) {
      return state.queueVisitors;
    } else {
      return {
        visitor: {
          initials: ""
        },
        visitors: [],
      };
    }
  },
  graveyard: (state) => {
    return state.graveyard;
  },
  patient: (state) => {
    return state.patient;
  },
  searchPage: (state) => {
    return state.searchPage;
  },
  buttonState: (state) => {
    return state.buttonState;
  }
};
