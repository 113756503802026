export default {
  methods: {
    logOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('companyId');
      localStorage.removeItem('buildingId');
      localStorage.removeItem('companyName');
      this.$router.push('/admin/login');
      this.$store.commit('setLangs', [
        { name: 'en' },
        { name: 'no' }
      ]);
    }
  }
};
