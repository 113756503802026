import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import constants from "@/store/admin/constants/admin";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to get current site details
     *  @param {string|optional} id. Current site Id
     */
    async getSiteById(id = this.$route.params.serviceId, module) {
      let url = `/Sites/${id}`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setSite, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to update site properties
     *  @param {Object} model. Site model with properties
     *  @return {Object | null} Returns site object if success or null
     */
    async saveSite(model) {
      const data = await this.processRequest("/Sites", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to copy site by Id
     *  @param {String} id. SiteId
     *  @return {Object | null} Returns site object if success or null
     */
    async copySite(id, companyId) {
      const data = await this.processRequest(companyId ? `/Sites/Copy/${id}?companyId=${companyId}` : `/Sites/Copy/${id}`, "post");
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    /**
     * * Send request to API to get company sites list
     * *  @param {string|optional} companyId. Current site Id
     */
    async getCompanySitesList(companyId = localStorage.companyId) {
      const data = await this.processRequest("/Sites/List");
      if (data.ok) {
        await this.$store.dispatch(constants.setSites, data.body);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },

    /**
     * * Send request to API to get site details for client
     * *  @param {string|optional} siteId. Current site Id
     */
    async getClientSiteDetails(siteId = this.$route.params.siteId, module = false) {
      let url = `/Sites/${siteId}/client`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url);
      if (data?.body?.success) {
        const site = data.body.message;
        if (site.width) {
          site.siteHeight = site.height;
          site.siteWidth = site.width;
          for (const page of site.sitePages) {
            for (const pc of page.pageComponents) {
              pc.pageComponentContents = pc.componentContents;
              pc.properties = JSON.parse(pc.properties);
              pc.styles = JSON.parse(pc.styles);
            }
          }
        }
        await this.$store.dispatch(constants.setSite, site);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
  }
};
