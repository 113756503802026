<template>
  <div class="wrapper">
    <router-view v-if="showRoutes"></router-view>
    <spinner v-if="$store.state.loading"/>
  </div>
</template>

<script>
import addRoutesDynamic from '@/mixins/client/wc/addRoutesDynamic';
import setLangs from "@/mixins/language/setLangs";
import requests from "@/mixins/admin/requests/requests";
import Vue from "vue";
import VueAWN from "vue-awesome-notifications";
import lang from "@/helpers/lang";
import getType from "@/mixins/language/getLangType";
import clientSocket from '@/mixins/socket/socketClient';

const spinner = () => import('@/components/cms/common/spinner');

export default {
  name: "app",
  mixins: [addRoutesDynamic, setLangs, requests, getType, clientSocket],
  data() {
    return {
      // responsible for showing routes in all app
      showRoutes: false,
      lang,
    };
  },
  computed: {
    lg() {
      return this.getType();
    },
  },
  components: {
    spinner
  },
  methods: {},
  async beforeMount() {
    if (this?.lg?.length) {
      Vue.use(VueAWN, {
        duration: 2000,
        position: 'top-right',
        labels: {
          success: this.lang[this.lg].toast.success,
          warning: this.lang[this.lg].toast.warning,
          alert: this.lang[this.lg].toast.alert,
        }
      });
    }
    const dynamic = this.$route?.query?.dynamic;
    if (dynamic) {
      const siteId = this.getParameterFromQuery();
      await this.getClientSiteDetails(siteId, dynamic);
      this.initClientSocket(siteId);
      // try to push new routes to the all routes
      this.addRoutesDynamic(this.$store.state.site?.sitePages);
      // when routes are loaded -> show route
      this.showRoutes = true;
    } else {
      // if not dynamic routes allow to open immediately
      this.showRoutes = true;
    }
    if (!localStorage.lang) {
      localStorage.lang = 'no';
    }
  }
};
</script>
<style lang="scss">
  @import '~vue-awesome-notifications/dist/styles/style.scss';
  @import '~@/styles/general/spinner';
</style>
