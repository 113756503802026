export default {
  siteResolution: { width: null, height: null },
  selectedPage: {},
  coefficient: 1,
  employeeSearchPhrase: "",
  employeeList: [],
  allEmployees: [],
  employeeObj: {},
  finishRegisterSettings: {},
  defaultEmployeeList: [],
  visitorObj: {},
  localPageSize: {},
  dropinObject: {},
  validate: {},
  companyQueueLines: [],
  queueVisitors: {},
  jobDepartments: [],
  jobDepartment: {},
  infoSearchCategories: [],
  infoSearchCategory: {},
  infoSearchSubCategory: {},
  departmentUsers: [],
  elementsShifted: null,
  graveyard: {},
  patient: {},
  searchPage: {},
  buttonState: {}
};
