import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const employeeListSettings = {
  title: 'Employee List',
  [componentKeys.name]: 'employeesListBase',

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.bgColor]: "grey",
    [componentKeys.color]: "#fff",
    [componentKeys.borderColor]: "grey",
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.fontSize]: "16px",
  },
  [componentKeys.properties]: {}
};
