import navigationKeys from "@/helpers/cms/navigationKeys";

export default [
  {
    category: "Service",
    name: "Sites",
    routeName: "services",
    normalized: navigationKeys.sites,
    [navigationKeys.sites]: {
      read: false,
      write: false
    }
  },
  {
    category: "Template",
    name: "Templates",
    routeName: "templates",
    normalized: navigationKeys.templates,
    [navigationKeys.templates]: {
      read: false,
      write: false
    }
  },
  {
    category: "Template",
    name: "Template Pages",
    routeName: "template-pages",
    normalized: "TemplatePages",
    TemplatePages: {
      read: false,
      write: false
    }
  },
  {
    category: "Site",
    name: "Site Pages",
    routeName: "sitepages",
    normalized: "SitePages",
    SitePages: {
      read: false,
      write: false
    }
  },
  {
    category: "Visitor",
    name: "Evacuation List",
    routeName: "visitor",
    normalized: navigationKeys.visitors,
    [navigationKeys.visitors]: {
      read: false,
      write: false
    }
  },

  // Admin rotes
  {
    category: "Building",
    name: "Buildings",
    routeName: "buildings",
    normalized: navigationKeys.buildings,
    [navigationKeys.buildings]: {
      read: false,
      write: false
    }
  },
  {
    category: "Company",
    name: "Companies",
    routeName: "companies",
    normalized: navigationKeys.companies,
    [navigationKeys.companies]: {
      read: false,
      write: false
    }
  },
  {
    category: "JobDepartments",
    name: "JobDepartments",
    routeName: "departments",
    normalized: navigationKeys.jobDepartments,
    [navigationKeys.jobDepartments]: {
      read: false,
      write: false
    }
  },
  {
    category: "User",
    name: "Users List",
    routeName: "users",
    normalized: navigationKeys.users,
    [navigationKeys.users]: {
      read: false,
      write: false
    }
  },
  {
    category: "UserActivity",
    name: "User Activity",
    routeName: "userActivity",
    normalized: navigationKeys.userActivities,
    [navigationKeys.userActivities]: {
      read: false,
      write: false
    }
  },
  {
    category: "Notification",
    name: "Messages",
    routeName: "messages",
    normalized: navigationKeys.notifications,
    [navigationKeys.notifications]: {
      read: false,
      write: false
    }
  },
  {
    category: "Role",
    name: "Users Groups",
    routeName: "user-group",
    normalized: navigationKeys.userRoles,
    [navigationKeys.userRoles]: {
      read: false,
      write: false
    }
  },
  {
    category: "Statistics",
    name: "Statistics",
    routeName: "statistics",
    normalized: navigationKeys.statistics,
    [navigationKeys.statistics]: {
      read: false,
      write: false
    }
  },

  // Company admin routes
  {
    category: "AdminBuilding",
    name: "Admin Buildings",
    routeName: "admin-buildings",
    normalized: navigationKeys.adminBuildings,
    [navigationKeys.adminBuildings]: {
      read: false,
      write: false
    }
  },
  {
    category: "AdminCompany",
    name: "Admin Companies",
    routeName: "admin-companies",
    normalized: navigationKeys.adminCompanies,
    [navigationKeys.adminCompanies]: {
      read: false,
      write: false
    }
  },
  {
    category: "AdminDepartment",
    name: "AdminDepartments",
    routeName: "admin-departments",
    normalized: navigationKeys.adminDepartments,
    [navigationKeys.adminDepartments]: {
      read: false,
      write: false
    }
  },
  {
    category: "Category",
    name: "Categories",
    routeName: "job-departments",
    normalized: navigationKeys.jobDepartments,
    [navigationKeys.jobDepartments]: {
      read: false,
      write: false
    }
  },
  {
    category: "JobTitle",
    name: "Job Titles",
    routeName: "job-titles",
    normalized: navigationKeys.jobTitles,
    [navigationKeys.jobTitles]: {
      read: false,
      write: false
    }
  },
  {
    category: "Employer",
    name: "Employees",
    routeName: "employees",
    normalized: navigationKeys.employees,
    [navigationKeys.employees]: {
      read: false,
      write: false
    }
  },
  {
    category: "EmployeeExtensions",
    name: "Employee Extensions",
    normalized: "EmployeeExtensions",
    EmployeeExtensions: {
      read: false,
      write: false
    }
  },
  {
    category: "DropIn",
    name: "DropIn",
    routeName: "dropin",
    normalized: navigationKeys.dropInUsers,
    [navigationKeys.dropInUsers]: {
      read: false,
      write: false
    }
  },
  {
    category: "EmployerNotification",
    name: "Employer Messages",
    routeName: "employees-messages",
    normalized: navigationKeys.employeeNotifications,
    [navigationKeys.employeeNotifications]: {
      read: false,
      write: false
    }
  },
  {
    category: "DropinNotification",
    name: "DropinNotifications",
    routeName: "dropin-messages",
    normalized: navigationKeys.dropInNotifications,
    [navigationKeys.dropInNotifications]: {
      read: false,
      write: false
    }
  },
  {
    category: "VisitorNotification",
    name: "Visitor Messages",
    routeName: "visitor-messages",
    normalized: navigationKeys.visitorNotifications,
    [navigationKeys.visitorNotifications]: {
      read: false,
      write: false
    }
  },
  {
    category: "EvacuationNotification",
    name: "Evacuation Messages",
    routeName: "evacuation-messages",
    normalized: navigationKeys.evacuationMessages,
    [navigationKeys.evacuationMessages]: {
      read: false,
      write: false
    }
  },
  {
    category: "VisitExpress",
    parent: "CompanyAdmin",
    name: "Visit Express",
    routeName: "preregistration-messages",
    normalized: "VisitExpress",
    VisitExpress: {
      read: false,
      write: false
    }
  },

  // Settings tabs
  {
    category: "MessageSettings",
    name: "Message Settings",
    routeName: "settings-message",
    normalized: navigationKeys.messageSettings,
    [navigationKeys.messageSettings]: {
      read: false,
      write: false
    }
  },
  {
    category: "SiteSettings",
    name: "Site Settings",
    routeName: "settings-sites",
    normalized: navigationKeys.siteSettings,
    [navigationKeys.siteSettings]: {
      read: false,
      write: false
    },
  },

  // Schedule tabs
  {
    category: "Schedule",
    name: "Schedule",
    routeName: "schedule",
    normalized: navigationKeys.schedules,
    [navigationKeys.schedules]: {
      read: false,
      write: false
    }
  },

  // MeetingRoom tabs
  {
    category: "MeetingServices",
    name: "MeetingServices",
    routeName: "meetingServices",
    normalized: navigationKeys.meetingServices,
    [navigationKeys.meetingServices]: {
      read: false,
      write: false
    }
  },
  {
    category: "MeetingRoom",
    name: "MeetingRoom",
    routeName: "meetingroom",
    normalized: navigationKeys.meetingRooms,
    [navigationKeys.meetingRooms]: {
      read: false,
      write: false
    }
  },
  {
    category: "Meeting",
    name: "Meeting",
    routeName: "meetings",
    normalized: 'Meetings',
    Meetings: {
      read: false,
      write: false
    }
  },

  // Preregistration tabs
  {
    category: "Preregistration",
    name: "Preregistration",
    routeName: "preregistration",
    normalized: navigationKeys.preRegistrations,
    [navigationKeys.preRegistrations]: {
      read: false,
      write: false
    }
  },

  // InfoSearch tabs
  {
    category: "InfoSearch",
    name: "InfoSearch",
    routeName: "infosearch-sites",
    normalized: navigationKeys.infoSearch,
    [navigationKeys.infoSearch]: {
      read: false,
      write: false
    }
  },
  {
    category: "InfoSearchSites",
    name: "InfoSearchSites",
    routeName: "infosearch-sites",
    normalized: navigationKeys.infoSearchSites,
    [navigationKeys.infoSearchSites]: {
      read: false,
      write: false
    }
  },
  {
    category: "InfoSearchAreas",
    name: "InfoSearchAreas",
    routeName: "infosearch-areas",
    normalized: navigationKeys.infoSearchAreas,
    [navigationKeys.infoSearchAreas]: {
      read: false,
      write: false
    }
  },
  {
    category: "InfoSearchPatients",
    name: "InfoSearchPatients",
    routeName: "infosearch-patients",
    normalized: navigationKeys.infoSearchPatients,
    [navigationKeys.infoSearchPatients]: {
      read: false,
      write: false
    }
  },

  // Queue tabs
  {
    category: "QueueLines",
    name: "QueueLines",
    routeName: "queue-system-lines",
    normalized: navigationKeys.queueLines,
    [navigationKeys.queueLines]: {
      read: false,
      write: false
    }
  },

  // EnturAPI
  {
    category: "Entur",
    name: "Entur",
    routeName: "entur-lines",
    normalized: navigationKeys.entur,
    [navigationKeys.entur]: {
      read: false,
      write: false
    }
  },

  // EasyPark
  {
    category: "EasyPark",
    name: "EasyPark",
    normalized: navigationKeys.easyPark,
    [navigationKeys.easyPark]: {
      read: false,
      write: false
    }
  },

  // Taxi
  {
    category: "Taxi",
    name: "Taxi",
    normalized: navigationKeys.taxi,
    [navigationKeys.taxi]: {
      read: false,
      write: false
    }
  },

  // PreRegisterMeetings
  {
    category: "PreRegisterMeetings",
    name: "PreRegisterMeetings",
    normalized: navigationKeys.preRegisterMeetings,
    [navigationKeys.preRegisterMeetings]: {
      read: false,
      write: false
    }
  }
];
