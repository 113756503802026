import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const langSwitch = {
  title: 'Language Switch',
  [componentKeys.name]: 'langSwitcherBase',

  [componentKeys.styles]: {
    ...defaultPosition,
  },
  [componentKeys.properties]: {}
};
