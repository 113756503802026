import _ from "lodash";

export default {
  getSiteDepartmentsWithChecked: (state, getters) => {
    return state?.departments?.map(el => {
      el = {
        ...el,
        isChecked: false
      };
      if (state.site && Object.keys(state.site).length > 0) {
        const isFound = state?.site?.siteDepartments?.find(item => item.departmentId === el.id);
        if (isFound) {
          el = {
            ...el,
            isChecked: true
          };
        }
      }
      const active = el?.content?.find(c => c.languageId === state.selectedLang.id);
      el = { ...el, name: active?.name || el.content[0].name || '' };
      return el;
    });
  },
  getPages: (state) => {
    return state.pages
      .map(p => ({
        ...p,
        active: p?.active ?? false
      }));
  },
  getTemplates: (state) => {
    return state.templates
      .map(t => ({
        ...t,
        active: t?.active ?? false
      }));
  },
  getPageRoutes: (state, getters) => {
    return getters.getPages.filter(el => el.id !== getters.getActivePage?.id);
  },
  getActivePage: (state) => {
    return state.pages.find(p => p.active) ?? null;
  },
  getActiveTemplate: (state) => {
    return state.templates.find(t => t.active) ?? null;
  },
  getUsersMessages: (state) => {
    if (!state.notifications?.map) return [];
    return state.notifications.map(el => {
      const mainContent = el?.content?.find(c => c.languageId === state.selectedLang.id);
      const secondaryContent = el?.content?.find(c => c.languageId !== state.selectedLang.id);
      let contentText;
      if (mainContent.content !== '') {
        contentText = mainContent?.content.length > 190 ? mainContent.content.substring(0, 100) + "..." : mainContent.content;
      } else {
        contentText = secondaryContent?.content.length > 190 ? secondaryContent.content.substring(0, 100) + "..." : secondaryContent.content;
      }
      el = {
        ...el,
        content: contentText || ''
      };
      return el;
    });
  },
  getAdminDepartmentsList: (state) => {
    return state?.departments.map(el => {
      const active = el?.content?.find(c => c.languageId === state.selectedLang.id);
      el = { ...el, name: active?.name || el.content[0].name || '' };
      return el;
    });
  },
  getCategoriesList: (state) => {
    return state?.categories.map(el => {
      const active = el?.content?.find(c => c.languageId === state.selectedLang.id);
      el = { ...el, name: active?.name || el.content[0].name || '' };
      return el;
    });
  },
  getJobTitlesList: (state) => {
    return state?.jobTitles?.map(el => {
      const active = el?.content?.find(c => c.languageId === state.selectedLang.id);
      el = { ...el, name: active?.name || el.content[0].name || '' };
      return el;
    });
  },
  getJobDepartmentsList: (state) => {
    return state?.jobDepartments?.map(el => {
      const active = el?.content?.find(c => c.languageId === state.selectedLang.id);
      el = { ...el, name: active?.name || el.content[0].name || '' };
      return el;
    });
  },
  getBuildings: (state) => (editedBuildings) => {
    return state.buildings.map(el => {
      if (!editedBuildings) el = { ...el, isChecked: false };
      // check if company assigned to the building
      if (!_.isEmpty(state?.company)) {
        const isFinded = state.company.buildings.find(item => item.id === el.id);
        if (isFinded) {
          if (!editedBuildings) el = { ...el, isChecked: true };
        }
      }
      return el;
    });
  },
  getUsers: (state) => (editedUsers) => {
    return state.users.map(el => {
      if (!editedUsers) {
        el = { ...el, isChecked: el.isChecked || false };
      }
      // check if company assigned to the user
      if (!_.isEmpty(state?.company)) {
        const isFinded = state.company.users.find(item => item.id === el.id);
        if (isFinded) {
          if (!editedUsers) {
            el = { ...el, isChecked: el.isChecked || true };
          }
        }
      }

      if (el.notificationId) {
        el.notification.content = el.notification.content.map(item => {
          item = { ...item, show: false };
          if (item.languageId === state.selectedLang.id) {
            item = { ...item, show: true };
          }
          return item;
        });

        const active = el.notification.content.find(c => c.show);
        el.notification = {
          ...el.notification,
          selectedContent: active ? active.content : ''
        };
      }

      return { ...el, fullName: `${el.firstName} ${el.lastName}`, phoneNumber: el.phoneNumber };
    });
  },
  getCheckedUsersByObjectPassed: (state) => (obj, isEditedUser) => {
    return state.users?.map(el => {
      if (!isEditedUser) {
        el = { ...el, isChecked: false };
      }
      // check if department assigned to the user
      if (!_.isEmpty(obj)) {
        const isFinded = obj.users && obj.users.find(item => item.id === el.id);
        if (isFinded) {
          if (!isEditedUser) {
            el = { ...el, isChecked: true };
          }
        }
      }
      if (el.notificationId) {
        el.notification.content = el.notification.content.map(item => {
          item = { ...item, show: false };
          if (item.languageId === state.selectedLang.id) {
            item = { ...item, show: true };
          }
          return item;
        });

        const active = el.notification.content.find(c => c.show);
        el.notification = {
          ...el.notification,
          selectedContent: active ? active.content.length > 50
            ? active.content.substring(0, 20) + "..."
            : active.content : ''
        };
      }
      return {
        ...el,
        fullName: el.firstName + ' ' + el.lastName,
        phoneNumber: el.countryCode ? el.countryCode + el.phoneNumber : el.phoneNumber
      };
    });
  },
  getCheckedMessagesByObjectPassed: (state) => (obj, isChanged, isCreatingNew) => {
    let notifications = state.notifications?.map(el => {
      if (!isChanged) {
        el = { ...el, isChecked: false };
      }
      el.content = el.content.map(item => {
        item = { ...item, show: false };
        if (item.languageId === state.selectedLang.id) {
          item = { ...item, show: true };
        }
        return item;
      });
      const active = el.content.find(c => c.show);
      el = {
        ...el,
        text: active ? active.content.length > 190
          ? active.content.substring(0, 100) + "..."
          : active.content : ''
      };
      return el;
    });
    if (isCreatingNew) {
      if (notifications?.length > 0) {
        if (!isChanged) {
          notifications[0] = { ...notifications[0], isChecked: true };
        }
      }
    } else {
      if (obj?.notificationId?.length) {
        notifications = notifications.map(el => {
          if (el.id === obj.notificationId && !isChanged) {
            el = { ...el, isChecked: true };
          }
          return el;
        }
        );
      }
    }
    return notifications;
  },
  getCheckedCompaniesByObjectPassed: (state) => (obj, isChanged, isCreatingNew) => {
    let companies = state.companies?.map(el => {
      if (!isChanged) {
        el = { ...el, isChecked: false };
      }
      return el;
    });
    if (isCreatingNew) {
      if (companies?.length > 0) {
        if (!isChanged) {
          companies[0] = { ...companies[0], isChecked: true };
        }
      }
    } else {
      if (obj?.companyId?.length) {
        companies = companies?.map(el => {
          if (el.id === obj.companyId && !isChanged) {
            el = { ...el, isChecked: true };
          }
          return el;
        });
      } else {
        companies = [];
      }
    }
    return companies;
  },
  getCheckedDepartmentsByObjectPassed: (state) => (obj, isChanged) => {
    return state.departments?.map(el => {
      if (!isChanged) {
        el = { ...el, isChecked: false };
      }
      // check if message assigned to the department
      const isFinded = obj?.find(item => item.id === el.id);
      if (isFinded) {
        if (!isChanged) {
          el = { ...el, isChecked: true };
        }
      }
      el.content = el.content.map(item => {
        item = { ...item, show: false };
        if (item.languageId === state.selectedLang.id) {
          item = { ...item, show: true };
        }
        return item;
      });
      const active = el.content.find(c => c.show);
      el = { ...el, name: active ? active.name : '' };
      return el;
    });
  }
  // getAccessRoutes: (state) => {
  //   return state.accessRoutes.filter(el => (el.normalized === link.nameKey && el[el.normalized]).read)
  // }
};
