import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import constants from "@/store/admin/constants/admin";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  methods: {
    /**
     * * Send request to API to get all building visitors
     *  @param {String} buildingId. Id of current building
     */
    async getBuildingVisitors(buildingId) {
      const data = await this.processRequest(`/visitors/building/${buildingId}`);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setBuildingVisitors, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },

    /**
     * * Send request to API to save visitors into db
     *  @param {Array} model. Visitor model
     *  @return {Object | null} Returns visitors array if success or null
     */
    async preRegistrationLink(userId) {
      const response = await this.processRequest(`/PreRegistrations/Url?employeeId={userId}`);
      if (response?.body?.success) {
        // don't need to save into the store
        return response.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(response));
        return null;
      }
    }
  }
};
