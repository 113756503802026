import MiddlewareService from "@/MiddlewareService";
import getErrorResponse from "@/mixins/getResponseData";
import constants from "@/store/wc/constants/wc";

export default {
  mixins: [MiddlewareService, getErrorResponse],
  methods: {
    /**
     * * Send request to API to get company Queue Lines
     * *  @param {string|optional} companyId. Current company Id
     */
    async getQueueLinesByCompanyId(companyId = localStorage.companyId) {
      const route = `/QueueLines/company/${companyId}`;
      const data = await this.processRequest(route);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setCompanyQueueLines, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponseWithMessage(data));
      }
    },
  }
};
