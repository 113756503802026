import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from "@/helpers/wc/positionSettings";

export const departmentUsersSettings = {
  title: "Department Users",
  [componentKeys.name]: "departmentUsersBase",

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.color]: "#000000",
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.textAlign]: "left",
    [componentKeys.fontSize]: "16px",
    [componentKeys.userItemWidth]: "25",
    [componentKeys.paddingTop]: "0",
    [componentKeys.paddingRight]: "15",
    [componentKeys.paddingBottom]: "0",
    [componentKeys.paddingLeft]: "15",
    [componentKeys.justifyContent]: "center",
  },
  [componentKeys.properties]: {
    [componentKeys.routeName]: "",
    [componentKeys.employeesCount]: 6,
    [componentKeys.jobDepartment]: "",
    [componentKeys.messageToEmployee]: {},
    [componentKeys.fetchFromDepartment]: false,
    [componentKeys.showName]: true,
    [componentKeys.showJobTitle]: false,
    [componentKeys.showPhone]: true,
    [componentKeys.breakLine]: false,
    [componentKeys.messageTagVisitorCompany]: false,
    [componentKeys.messageTagVisitorName]: false,
    [componentKeys.messageTagVisitorPhone]: false,
    [componentKeys.messageTagVisitorAttendants]: false,
    [componentKeys.sendSms]: false,
    [componentKeys.sendEmail]: false,
    [componentKeys.redirectToRegister]: false,
  }
};
